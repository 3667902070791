<template>
	<b-card title="List Custom Service">
		<b-row class="mb-2">
			<b-col cols="12" md="6">
				<b-button
					@click="cleanUpForm()" 
					v-b-modal.form-user-modal
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Create Custom Service
				</b-button>
			</b-col>
		</b-row>
		<b-table 
			striped 
			hover 
			:items="services"
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ index + 1 }}
			</template>

			<template v-slot:cell(status)="{ item }">
				<span
					class="badge badge-pill badge-success"
					v-if="item.status === 'active'"
				>
					Aktif
				</span>
				<span
					class="badge badge-pill badge-danger"
					v-if="item.status === 'inactive'"
				>
					Tidak Aktif
				</span>
				<span
					class="badge badge-pill badge-secondary"
					v-if="item.status === 'coming_soon'"
				>
					Segera Hadir
				</span>
				<span
					class="badge badge-pill badge-warning"
					v-if="item.status === 'maintenance'"
				>
					Maintenance
				</span>
			</template>

			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<b-badge 
						variant="warning"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-edit-icon`"
							icon="EditIcon"
							class="cursor-pointer"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Edit Data'"
							@click="editItem(item)"
							v-if="checkPermission('update service')"
						/>
					</b-badge>

					<b-badge 
						variant="danger"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-preview-icon`"
							icon="TrashIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							@click="deleteItem(item.uuid)"
						/>
					</b-badge>

				</div>
			</template>
		</b-table>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getData"
					/>
				</div>
			</div>
		</div>
		<b-modal
			id="form-user-modal"
			centered
			:title="editId != null ? 'Edit Custom Service' : 'Add Custom Service'"
			hide-footer
			no-close-on-backdrop
		>
			<ValidationObserver>
				<!-- <form @submit.prevent="updateItem(formPayload)"> -->
				<form>
					<div class="form-group">
						<label>Nama</label>
						<validation-provider
							name="Nama"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.name"
							>
							<span
								v-for="(validation, index) in validations.name"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Unit</label>
						<select 
							class="form-control"
							v-model="formPayload.unit"
						>
							<option value="">-- Pilih Unit --</option>
							<option value="record">Record</option>
							<option value="month">Bulan</option>
							<option value="minutes">Menit</option>
						</select>
					</div>
					<div class="form-group">
						<label>Status</label>
						<select 
							class="form-control"
							v-model="formPayload.status"
						>
							<option value="">-- Pilih Status --</option>
							<option value="active">Aktif</option>
							<option value="inactive">Tidak Aktif</option>
							<option value="coming_soon">Segera Hadir</option>
							<option value="maintenance">Maintenance</option>
						</select>
					</div>
					<div class="form-group">
						<label>Harga</label>
						<validation-provider
							name="Harga"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.price"
							>
							<span
								v-for="(validation, index) in validations.price"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Qty</label>
						<validation-provider
							name="Qty"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.qty"
							>
							<span
								v-for="(validation, index) in validations.qty"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					
					<div class="form-group form-row align-items-center">
						<label class="col-12 col-md-4 col-form-label">Category</label>
						<validation-provider
							name="status"
							rules="required"
							v-slot="{ errors }"
						>
							<div class="col demo-inline-spacing p-0">
								<div class="custom-control custom-radio mt-0">
									<input 
										name="Category" 
										class="custom-control-input" 
										:state="errors.length > 0 ? false:null"
										type="radio" 
										id="radioAdministrasi" 
										value="Administrasi" 
										v-model="formPayload.category"
									>
									<label 
										class="custom-control-label" 
										for="radioAdministrasi"
									>
										Administrasi
									</label>
								</div>
								<div class="custom-control custom-radio mt-0">
									<input 
										name="Category" 
										class="custom-control-input" 
										:state="errors.length > 0 ? false:null"
										type="radio" 
										id="radioPajak"
										value="Pajak"
										v-model="formPayload.category"
									>
									<label 
										class="custom-control-label" 
										for="radioPajak"
									>
										Pajak
									</label>
								</div>
							</div>
							<small
								v-for="(validation, index) in validations.status"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Description</label>
						<textarea 
							class="form-control"
							cols="30" rows="8"
							v-model="formPayload.description"
						/>
						<span
							v-for="(validation, index) in validations.description"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</span>
					</div>
					
					<b-button v-if="editId == null" variant="primary" @click="createItem()" v-bind:disabled="isLoading">
						Save Custom Service
					</b-button>
					<b-button v-else variant="primary" @click="updateItem()" v-bind:disabled="isLoading">
						Edit Custom Service
					</b-button>
				</form>
			</ValidationObserver>
		</b-modal>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import _ from 'lodash'
import { 
	BCard,
	BBadge,
	BRow,
	BCol,
	BCardText,
	BTable,
	BPagination,
	BSpinner,
	BButton,
	VBModal,
	VBTooltip
} from 'bootstrap-vue'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BBadge,
		BRow,
		BCol,
		BCardText,
		BTable,
		BPagination,
		BSpinner,
		BButton,
		ValidationProvider, 
		ValidationObserver,
	},

	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},
	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			// result:{},
			isLoading: false,
			fields: ['no', 'name', 'price', 'status' ,'unit', 'qty' ,'actions'],
			services: [],
			result:{},
			filter: {
				with_pagination: true,
				type: 'custom',
			},
			// formService: {
			// 	status:''
			// },
			editId: null,
			formPayload: Object,
			validations: ''
		}
	},

	computed: {
		rows() {
			return this.services.length
		}
	},

	methods: {
		cleanUpForm() {
			this.editId = null
			this.formPayload = {
				name: '',
				status: '',
				price: '',
				qyt: '',
				description: '',
				category: '',
				unit: '',
			}
		},

		getData(page) {
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/services', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.services = response.data.data.data
				this.isLoading = false
			})
		},

		preparePayload() {
			const form = new FormData()
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key)) {	
					form.append(key, this.formPayload[key])
				}
			}
			return form;
		},

		createItem() {
			var form = this.preparePayload();
			this.isLoading = true
			this.$http.post('admin/services', form, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-user-modal')
				this.getData(this.currentPage)
				successNotification(this, 'Success', 'Custom Service successfully created')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages.length > 0) {
				errorNotification(this, 'Oops!', error.response.data.meta.messages)  
				}
				this.isLoading = false
			})
		},
		updateItem() {
			var form = this.preparePayload();
			this.isLoading = true
			form.append('_method', 'PATCH');
			this.$http.post(`admin/services/${this.editId}`, form)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Custom Service Berhasil Diedit!')
				this.$bvModal.hide('form-user-modal')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Name', this.validations.name.join(' '))
					errorNotification(this, 'Aktivasi', this.validations.status.join(' '))
					errorNotification(this, 'Tipe Harga', this.validations.price_type.join(' '))
					errorNotification(this, 'Harga', this.validations.price.join(' '))
					errorNotification(this, 'Qty', this.validations.admin_price.join(' '))
					errorNotification(this, 'Deskripsi', this.validations.description.join(' '))
				}
			})
		},
		editItem(item) {
			this.cleanUpForm();
			this.editId = item.uuid
			this.formPayload = {
				uuid: item.uuid,
				name: item.name,
				status: item.status,
				price: item.price,
				qty: item.qty,
				description: item.description,
				category: item.category,
				unit: item.unit,
			}
			this.$bvModal.show('form-user-modal')
		},

		deleteItem(uuid) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Custom Service ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/services/' + uuid)
					this.getData()
					successNotification(this, 'Success', 'Custom Service berhasil dihapus!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},

	created() {
		this.getData()
	}
}
</script>